<template>
  <div class="relative">
		<button class="border-2 border-gray-300 rounded p-3 mt-3 ml-4 bg-white cursor-pointer" @click="goBack()">Back</button>
    <section class="bg-white rounded-lg flex flex-col justify-center mx-11/12 border-b-2 border-gray-400 ">
      <!-- <div class="profile-banner" :style="{'background-image': 'url(' + require('@/assets/banner.png') + ')'}">
      </div> -->

      <div class="profile-image-group">
        <div class="profile-image" :style="{'background-image': 'url(' + userdata.photo + ')'}">
        </div>
        <div class="flex flex-col justify-end mr-36 absolute right-0 bottom-10">
          <div class="text-left mx-3">
            <h3 class="font-bold text-5xl text-black">{{userdata.fname}} {{userdata.lname}}</h3>
          </div>
          <button class="bg-white min-w-16 px-4 py-2 mx-2 flex items-center justify-evenly relative border-2 border-gray-200 rounded-lg text-2xl">Message
          </button>
        </div>
      </div>

    </section>
    <section>
      <!-- Container -->
      <div class="flex flex-wrap mt-2 mx-2">
        <div class="w-1/2 px-2 my-2 py-3">
          <p class="m-0 text-gray-600 text-md">Phone</p>
          <p class="m-0 text-black text-2xl">{{userdata.phone}}</p>
        </div>
        <div class="w-1/2 px-2 my-2 py-3">
          <p class="m-0 text-gray-600 text-md">Email</p>
          <p class="m-0 text-black text-2xl">{{userdata.email}}</p>
        </div>
        <div class="w-1/2 px-2 my-2 py-3">
          <p class="m-0 text-gray-600 text-md">Status</p>
          <p class="m-0 text-black text-2xl">Active</p>
        </div>
        <div class="w-1/2 px-2 my-2 py-3">
          <p class="m-0 text-gray-600 text-md">Location</p>
          <p class="m-0 text-black text-2xl">123 Purring Dr,</p>
          <p class="m-0 text-black text-2xl">Wakanda, GA 30012</p>
          <p class="m-0 text-black text-2xl">UK</p>
        </div>
        <div class="w-1/2 px-2 my-2 py-3">
          <p class="m-0 text-gray-600 text-md">Birthday</p>
          <!-- <p class="m-0 text-black text-2xl">July 31, 1999</p> -->
          <div class="w-50">
            <b-form-datepicker
              id="example-datepicker"
              v-model="value"
              class="mb-2"
            ></b-form-datepicker>
          </div>
        </div>
        <div class="w-1/2 px-2 my-2 py-3">
          <p class="m-0 text-gray-600 text-md">License</p>

          <a class=" border border-gray-200 w-1/2 rounded-md pl-3 pr-4 py-3 flex items-center justify-between text-sm "  >
            <div class="w-0 flex-1 flex items-center">
              <!-- Heroicon name: solid/paper-clip -->
              <svg
                class="flex-shrink-0 h-5 w-5 text-gray-400"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z"
                  clip-rule="evenodd"
                />
              </svg>
              <span class="ml-2 flex-1 w-0 truncate"> License </span>
            </div>
            <div class="ml-4 flex-shrink-0">
              <span class="font-medium text-indigo-600 hover:text-indigo-500">
                Download
              </span>
            </div>
          </a>
        </div>

      </div>
    </section>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: "Profile",
  data() {
    return {
      userdata: {},
      tab: 0,
      userID: this.$route.params.userID
    };
  },
  methods:{
		goBack(){
			this.$router.back()
		},
    getUser(){
      const config = {
        headers: {
          userID: this.userID
        }
      }
      axios.get('https://skipsource.skylineexecutive.com/admin/users/profile', config
      ).then((res)=>{
        console.log(res.data)
        this.userdata = res.data.results
      }).catch((err) =>{
        console.log(err)
      })
    },
  },
	created(){
		this.getUser()
	}
}
</script>
<style scoped>
.DriverProfile {
  background-color: #fff;
  width: 100vw;
	margin: .5rem;
	margin-right: 7rem;
}

.profile-image-group{
  position:relative;
  width: 100%;
  margin: 2rem 0;
	margin-top: 4rem;
}
.profile-image{
  position:relative;
  border: 5px solid #fff;
  width: 16rem;
  height: 16rem;
  margin-left: 5%;
  border-radius: 100%;
  overflow:hidden;
  /* Create the parallax scrolling effect */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
}
</style>
